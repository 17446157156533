<template>
  <div class="shell-box">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path: `/routerPolicyManagement/${this.$route.meta.policyRoute}/policyManagement`,
        }"
        >员工列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>员工详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <detailsa :userInfo="policyDetail" />
    </div>
  </div>
</template>

<script>
import detailsa from "@/views/routerPerformanceManagement/components/staffManagementList/components/details.vue";
import {
  getUserPerformance,
  policyProcessRevoke,
} from "@/api/policy";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { downloadZIP } from "@/utils/index";
export default {
  components: { detailsa },
  data() {
    return {
      policyDetail: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.policyDetail = {
        userId: this.$route.query.id,
        year: Number(this.$route.query.year),
        month: Number(this.$route.query.month),
      };
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleEdit() {
      this.$router.push({
        path:
          `/routerPolicyManagement/${this.$route.meta.policyRoute}/policyEdit/` +
          this.policyDetail.id,
      });
    },
    handleCreateCorrectionOrder(e) {
      this.$router.push({
        path:
          "/routerPolicyManagement/createCorrectionOrder/" +
          this.$route.params.id,
      });
    },
    handleRevocation(item) {
      this.$confirm("是否确认撤回？", "撤销", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          policyProcessRevoke({
            processType: 2,
            bussId: this.policyDetail.id,
            processId: this.policyDetail.processId,
          }).then((res) => {
            this.getDetail();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消撤回",
          });
        });
    },
    downloadAllAttachment() {
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let arr = [];
      this.dataEcho.forEach((item) => {
        item.urls.forEach((urlitem) => {
          if (urlitem.url) {
            arr.push({
              name: `${urlitem.name}.${urlitem.suffix}`,
              url: urlitem.url,
            });
          }
        });
      });
      if (arr.length) {
        downloadZIP(arr, "附件")
          .then((res) => {
            loading.close();
          })
          .catch((err) => {
            loading.close();
          });
      } else {
        this.$message.warning("暂无可下载的附件！");
        loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: hidden;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 196px);
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>
