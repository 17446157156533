<template>
  <div class="el-collapse-box">
    <AssociationScroll :showTabs="false">
      <AssociationScrollView title="个人信息"
        name="0">
        <div style="padding: 18px 12px 12px">
          <el-descriptions class="margin-top"
            labelClassName="policy-label-style"
            contentClassName="policy-label-style">
            <el-descriptions-item label="姓名">
              <template slot="label"> 姓名 </template>
              {{ detailData.account || '--' }}
            </el-descriptions-item>
            <el-descriptions-item label="所属部门">
              <template slot="label"> 所属部门 </template>
              {{ detailData.deptName || '--' }}
            </el-descriptions-item>
            <el-descriptions-item label="所属岗位">
              <template slot="label"> 所属岗位 </template>
              {{ detailData.postName || '--' }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </AssociationScrollView>
      <AssociationScrollView title="绩效信息"
        name="1">
        <div style="padding: 18px 12px 12px">
           <el-date-picker 
            v-model="formData.year" @change="handleChangeYear"
            type="year" format="yyyy" value-format="yyyy"
            placeholder="选择年">
          </el-date-picker>
          <el-select style="margin-left: 20px" v-model="formData.month" clearable placeholder="选择月" @change="handleChangeYear">
            <el-option
              v-for="item in monthOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <div style="height:20px"></div>
          <el-descriptions class="margin-top"
            labelClassName="policy-label-style"
            contentClassName="policy-label-style">
            <el-descriptions-item label="佣金金额">
              <template slot="label"> 佣金金额 </template>
              {{ detailData.brokerageSumPremium }}
            </el-descriptions-item>
            <el-descriptions-item label="其他金额">
              <template slot="label"> 其他金额 </template>
              {{ detailData.otherSumPremium }}
            </el-descriptions-item>
            <el-descriptions-item label="合计金额">
              <template slot="label"> 合计金额 </template>
              <span style="font-weight: bold;font-size: 18px;color: #0080FF;">{{ detailData.sumAmount }}</span>
            </el-descriptions-item>
          </el-descriptions>
          <div class="el-descriptions-item__label has-colon policy-label-style">绩效详情</div>
          <el-table :data="performanceList" border>
            <el-table-column label="ID"
              type="index"
              width="50"></el-table-column>
            <el-table-column label="绩效编号" prop="serialNo" width="176"></el-table-column>
            <el-table-column label="部门" prop="salesmanDeptName" width="176"></el-table-column>
            <el-table-column label="保险公司" prop="primaryInsuranceCompanyName" width="176"></el-table-column>
            <el-table-column label="分支机构" prop="primaryInsuranceBranchName" width="176"></el-table-column>
            <el-table-column label="投保日期" prop="insuranceOfDate" width="176"></el-table-column>
            <el-table-column label="险种类型" prop="category" width="176">
              <template slot-scope="scope">
                {{ scope.row.category == 1 ? '工程机械险' : scope.row.category == 2 ? '车险' : '非车险' }}
              </template>
            </el-table-column>
            <el-table-column label="项目" prop="performanceTypeName" width="176"></el-table-column>
            <el-table-column label="细项" prop="projectName" width="176"></el-table-column>
            <el-table-column label="代理人" prop="agentName" width="176"></el-table-column>
            <el-table-column label="保单号" prop="policyNo" width="176"></el-table-column>
            <el-table-column label="保费" prop="totalPremium" width="176"></el-table-column>
            <el-table-column label="出单类型" prop="insertType" width="176"></el-table-column>
            <el-table-column label="代理商返点" prop="agentKick" width="176"></el-table-column>
            <el-table-column label="代理商费用" prop="agentAmount" width="176"></el-table-column>
            <el-table-column label="佣金绩效费率" prop="brokerage" width="176"></el-table-column>
            <el-table-column label="佣金金额" prop="brokerageAmount" width="176"></el-table-column>
            <el-table-column label="其他绩效费率" prop="otherBrokerage" width="176"></el-table-column>
            <el-table-column label="其他金额" prop="otherBrokerageAmount" width="176"></el-table-column>
            <el-table-column label="合计金额" prop="sumAmount" width="176"></el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="formData.pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="performanceTotal">
          </el-pagination>
        </div>
      </AssociationScrollView>
    </AssociationScroll>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import dayjs from "dayjs"
import {
  getUserPerformance,
} from "@/api/policy";
export default {
  props: {
    // detailData: {
    //   type: Object,
    //   default: () => { },
    // },
    userInfo: {
      type: Object,
      default: "",
    },
  },
  data() {
    return {
      isUpdateRecord: false,
      updateNew: [],
      updateOld: [],
      updateType: "附件",
      srcList: [],
      repetitionHistoryForm: {
        cardNo: "",
        salesmanId: "",
        dataType: '',
        orderType: ""
      },
      repetitionHistoryVisible: false,
      loginIdentity: '',
      detailData: {},
      formData: {
        year: dayjs().format('YYYY') + '',
        month: "",
        pageIndex: 1,
        pageSize: 10
      },
      performanceList: [],
      performanceTotal: 0,
      monthOptions: [
        {
          label: "一月",
          value: 1,
        },
        {
          label: "二月",
          value: 2,
        },
        {
          label: "三月",
          value: 3,
        },
        {
          label: "四月",
          value: 4,
        },
        {
          label: "五月",
          value: 5,
        },
        {
          label: "六月",
          value: 6,
        },
        {
          label: "七月",
          value: 7,
        },
        {
          label: "八月",
          value: 8,
        },
        {
          label: "九月",
          value: 9,
        },
        {
          label: "十月",
          value: 10,
        },
        {
          label: "十一月",
          value: 11,
        },
        {
          label: "十二月",
          value: 12,
        },
      ],
    };
  },
  watch: {
    userInfo: {
      handler(e) {
        e.year += '';
        Object.assign(this.formData, e);
        this.getList();
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.loginIdentity = this.$store.state.userInfo.loginIdentity;
  },
  components: { AssociationScroll, AssociationScrollView },
  methods: {
    handleSizeChange(val) {
      if (this.performanceTotal / val < this.formData.pageIndex && this.formData.pageIndex > 1) {
        let index = Math.ceil(this.performanceTotal / val)
        this.formData.pageIndex = index < 1 ? 1 : index;
      }
      this.formData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      this.getList();
    },
    getList() {
      getUserPerformance(
        this.formData
      ).then(res=>{
        this.detailData = res.data;
        this.performanceTotal = res.data?.performanceRecordPage?.total;
        this.performanceList = res?.data?.performanceRecordPage?.records || [];
      })
    },
    handleChangeYear(e) {
      this.getList();
    }
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  border-top: 1px dashed #999;
  padding-top: 15px;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
}
.personnel_repetition {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  padding: 8px 12px;
  margin-bottom: 24px;
  background: #cce6ff;
  border-radius: 8px;
  &_details {
    color: #0080ff;
    text-decoration: underline;
    cursor: pointer;
  }
}
.el-collapse-box {
  height: 100%;
  .view-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  .insurance-company {
    display: flex;
    justify-content: flex-end;
    max-width: 1000px;
    font-weight: bold;
    color: #4278c9;
    font-size: 16px;
    margin-top: 16px;
  }
  .clause-title {
    margin: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 20px;
  }
  .steps-title {
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    .decoration {
      height: 20px;
      width: 6px;
      border-radius: 3px;
      background-color: #4278c9;
      margin-right: 10px;
      margin-left: 14px;
    }
    .decoration2 {
      flex: 1;
      height: 1px;
      margin-left: 8px;
      margin-right: 20px;
      background-color: #cccccc;
    }
  }
  .steps-box {
    height: calc(100% - 50px);
    padding-top: 24px;
    overflow: auto;
    .step {
      display: flex;
      &-left {
        position: relative;
        width: 6px;
        background-color: #e1e1e1;
        margin-right: 10px;
        margin-left: 14px;
        img {
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%);
          width: 16px;
          height: 16px;
        }
      }
      &-left2 {
        background-color: transparent;
      }
      &-right {
        flex: 1;
        .time {
          font-size: 12px;
          font-weight: 300;
          color: #333333;
          margin-bottom: 4px;
        }
        .step-body {
          font-size: 12px;
          color: #333333;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.policy-label-style {
  align-items: center !important;
  font-size: 14px;
  color: #333333;
}
.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
  .ep-b {
    font-size: 14px;
    color: #0080ff;
    margin-left: 24px;
    cursor: pointer;
    border-bottom: 1px solid #0080ff;
  }
  .policy-ep-img {
    width: 20px;
    height: 20px;
    margin-right: 14px;
  }
}
</style>
